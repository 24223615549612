import { IconAccessoriesIncluded } from "components/icons/IconAccessoriesIncluded";
import { IconBadgeNew } from "components/icons/IconBadgeNew";
import { IconBadgeNewColor } from "components/icons/IconBadgeNewColor";
import { IconBadgeRebate } from "components/icons/IconBadgeRebate";
import { IconBadgeSale } from "components/icons/IconBadgeSale";
import { IconBadgeCyberSale } from "components/icons/IconBadgeCyberSale";
// import { IconBadgeLowInventory } from "components/icons/IconBadgeLowInventory";
import { IconPanelReady } from "components/icons/IconPanelReady";
import { IconBadgeQuickShip } from "components/icons/IconBadgeQuickShip";
import blackFriday from "public/assets/icons/BFBadge.png";
import cyberMonday from "public/assets/icons/CMBadge.png";
import Image from "next/image";


function useFlags({ flags, isTrade }){
    const flag = [];

    if( (flags?.custom_quick_ship_icon && flags?.custom_quick_ship_icon !== "No") || 
        flags?.custom_quick_ship_icon === "Yes"
    ){
        flag.push( <IconBadgeQuickShip /> );
    }
    
    if( ((flags?.sale_flag && flags?.sale_flag !== "No") || 
        flags?.sale_flag === "Yes") && 
        !isTrade 
    ){
        flag.push( <IconBadgeSale /> );
    }

    if( ((flags?.cyber_sale_flag && flags?.cyber_sale_flag !== "No") || 
        flags?.cyber_sale_flag === "Yes") && 
        !isTrade 
    ){
        flag.push( <IconBadgeCyberSale /> );
    } 

     if( ((flags?.black_friday_sale_flag && flags?.black_friday_sale_flag !== "No") || 
        flags?.black_friday_sale_flag === "Yes") && 
        !isTrade 
    ){
        flag.push( <Image src={blackFriday} /> );
    } 
     if( ((flags?.cyber_monday_sale_flag && flags?.cyber_monday_sale_flag !== "No") || 
        flags?.cyber_monday_sale_flag === "Yes") && 
        !isTrade 
    ){
        flag.push( <Image src={cyberMonday} />  );

    }
    if( ((flags?.new_color && flags?.new_color !== "No") || 
        flags?.new_color === "Yes") && 
        !isTrade 
    ){
        flag.push( <IconBadgeNewColor />  );
    }

    if( (flags?.ss_new && flags?.ss_new !== "No") || 
        flags?.ss_new === "Yes" || 
        (flags?.new && flags?.new !== "No")
    ){
        flag.push( <IconBadgeNew /> );
    }
    
    if( (flags?.rebatePromo && flags?.rebatePromo !== "No") || 
        flags?.rebate === "Yes" || 
        (flags?.rebate && flags.rebate !== "No") 
    ){
        flag.push( <IconBadgeRebate /> );
    }

    if( flags?.panel_ready === "Yes" || 
        (flags?.panel_ready && flags?.panel_ready !== "No")
    ){
        flag.push( <IconPanelReady /> );
    }

    // if( flags?.accessories_included === "Yes" || 
    //     (flags?.accessories_included && flags?.accessories_included !== "No")
    // ){
    //     flag.push( <IconAccessoriesIncluded /> );
    // }

    // return only the first one
    return flag[0] ?? null;
}


export default useFlags;