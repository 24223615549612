import PropTypes from 'prop-types';
import useFlags from './Flags.hook';
import Style from './Flags.module.scss'

function Flags({ flags, isTrade }){
    const bannerFlag = useFlags({ flags, isTrade })

    return(
        <div className={Style.block}>
            {bannerFlag}
        </div>
    )
}


Flags.propTypes = {
    flags: PropTypes.object,
    isTrade: PropTypes.bool
}


export default Flags