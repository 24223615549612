/* eslint-disable react-hooks/exhaustive-deps */

"use client"

import { cloudinaryProductImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader';
import Image from "next/image";
import { blurImage } from 'lib/Constants/blurImage';
import getFileName from 'lib/Utils/getFileName';


function PodImage({ 
    name = "", 
    image = "",
    fallBackImage = "",
    className = "",
    width = 300,
    height = 300,
    priority = false,
    isSecondary
 }){

    const primaryImage = image ? getFileName(image) : fallBackImage

    return(
        <Image 
            className={className}
            width={width}
            height={height}
            alt={name}
            priority={priority}
            quality={80}
            src={primaryImage}
            loader={cloudinaryProductImageLoader}
            placeholder={"blur"}
            blurDataURL={blurImage}
            style={{ width: "100%" }}
        />
    )
}

export default PodImage;