import axios from 'axios';
import { buildParamsObj, rewritePriceForAPIReq  } from 'components/applications/plp/helpers/PLP.helpers'
import { SearchApiConstants } from 'lib/Constants/searchApiConstants';
import qs from "qs"

import { parseCookies } from 'nookies'
/**
 * Search API
 */

const SearchAPI = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SEARCH_API_baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
    }

});

export async function trackProductsInteraction(productIds, eventType, queryID, position=false) {
    const cookies = parseCookies();
    const userToken = cookies.TEAK_searchUserToken;

    let eventPayload = { productIds, userToken, queryID };

    if (position) {
        eventPayload.positions = [position];
    }

    // Define the endpoint based on the interaction type
    let endPoint;
    switch (eventType) {
        case 'viewed':
            endPoint = SearchApiConstants.END_POINTS.PRODUCTS_VIEWED;
            break;
        case 'clicked':
            endPoint = SearchApiConstants.END_POINTS.PRODUCTS_CLICKED;
            break;
        case 'converted':
            endPoint = SearchApiConstants.END_POINTS.PRODUCTS_CONVERTED;
            break;
        default:
            console.error('Invalid event type');
            return;
    }

    try {
        await SearchAPI.post(endPoint, eventPayload);
        console.log(`Product ${eventType} successful`);
    } catch (err) {
        console.error(err);
    }
}

export function trackProductsViewed(productIds, queryID) {
    return trackProductsInteraction(productIds, 'viewed', queryID);
}

export function trackProductClicked(productId, queryID, position) {
    const productIds = [productId];
    return trackProductsInteraction(productIds, 'clicked', queryID, position);
}

export function trackProductsConverted(cartData) {
    if(cartData?.line_items?.physical_items.length > 0){
        const productIds = cartData?.line_items?.physical_items.map((item) => {
            return  item.product_id
        })

        const cookies = parseCookies();
        const queryID = cookies.TEAK_searchQueryID;

        trackProductsInteraction(productIds, 'converted', queryID);
        return true;
    }
    return false;
}

//choose default params based on ss url
function chooseDefaultParams(configURL){
    switch (true) {
        case (configURL.indexOf("autocomplete") > -1):
            return SearchApiConstants.AUTO_DEFAULT_PARAMS ;

        default: return SearchApiConstants.SEARCH_DEFAULT_PARAMS;
    }
}


//if the url is querystring get the params
function getParamsFromURL(q){
    let newObj = buildParamsObj(q, rewritePriceForAPIReq);
    return newObj
}

//custom request handler, change the params format before sending the request for eg, price
const requestHandler = (request) => {
    if (request?.params?.q) {
        const q = request.params.q;

        //if q is URL, get params
        request.params = (q.indexOf('/search?') > -1) ? getParamsFromURL(q) : rewritePriceForAPIReq(request.params)

    } else {
        request.params = rewritePriceForAPIReq(request.params)
    }

    //choose default params based on URL
    const requestParams = chooseDefaultParams(request.url);

    if (request?.params?.resultsPerPage && !isNaN(Number(request.params.resultsPerPage))) {
        requestParams.resultsPerPage = Number(request.params.resultsPerPage);
    }

    //keep categories_hierarchy as the first parameter (CDN invalidations needs it)
    request.params = {
        ...request.params,
        ...requestParams
    };

    return (request)
};


const errorHandler = error => {
    return Promise.reject(error);
};


SearchAPI.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);


export default SearchAPI;