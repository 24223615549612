import GraphCMS from "services/cms/GraphQL.service";
import GetReturnsText from "services/cms/queries/GetReturnsText";
import GetBrandInfo from "services/cms/queries/GetBrandInfo";
import ProductApi from "services/pip/Product.service"
import GetToolTips from "services/cms/queries/GetToolTips";
import GetFabricTableByBrand from "services/cms/queries/GetFabricTableByBrand";

const productApi = new ProductApi();
const cmsGraphQl = new GraphCMS();




// get all product slugs
async function GetProductSlugs(){
    return await productApi.get("/api/catalog/products/slugs").then(response => response);
}



/**
 * Get single product data
 * @param {String} slug 
 * @returns product data object
 */

function GetProductData(slug){
    return slug && productApi.get(`/api/catalog/products/${slug}`).then(response => response?.product);
}



/**
 * Get Product Brand info from CMS
 * @param {String or Number} brandId 
 * @returns category array
 */

function GetBrandInformation(brandId){
    const brandInfo = GetBrandInfo(brandId);
    return cmsGraphQl.post(brandInfo).then(response => response?.data?.category);
}




// Get returns tab text from the CMS
function GetReturnsTabText(){
    const returns = GetReturnsText()
    return cmsGraphQl.post(returns).then(response => response?.data?.entry);
}




/**
 * Get Product Specifications
 * @param {Array} productIds 
 * @returns product specs
 */

async function GetProductSpecifications(productIds = []){
    return await productApi.get(`/api/catalog/products/specs?ids=${productIds.join(",")}`).then(response => response?.products)
}


/**
 * Gets Tool tips from the CMS
 * @param {array} tipSlugs 
 * @returns tip entires array
 */

async function GetSpecToolTips(tipSlugs){
    const tipQuery = GetToolTips(tipSlugs)
    return await cmsGraphQl.post(tipQuery).then(response => response?.data?.entries)
}



/**
 * Gets Fabric Table data by Brand Id from the CMS
 * @param {string} brandId 
 * @returns fabric array
 */

async function GetBrandFabricTable(brandId){
    const brandTable = GetFabricTableByBrand(brandId)
    return await cmsGraphQl.post(brandTable).then(response => response?.data?.category?.fabrics)
}





export {
    GetBrandFabricTable,
    GetSpecToolTips,
    GetReturnsTabText,
    GetBrandInformation,
    GetProductData,
    GetProductSlugs,
    GetProductSpecifications
};