import { useEffect, useMemo, useState } from "react"
import { GetSpecToolTips } from "services/pip/ProductContext.service"

/**
 * Gets tool tips from the cms that match the Akeneo IDs passed in
 * For this to work the array of ids, should be underscored (typical)
 * and come from Akeneo or Search Spring
 * 
 * Note: Can be hacked to use as a general tooltips getter from the CMS by passing in an array
 * of CMS slugs from the tooltip channel with underscores in place of the dashes
 * 
 * @param {array} dataArray 
 * @returns object of tool tips
 */

export function useToolTips({ facets }){
    const facetItems = useMemo(() => facets.map(facet => facet?.field ?? facet), [ facets ])
    const [ toolTips, setToolTips ] = useState(null)


    useEffect(() => {
        if( !toolTips ){
            const tipSlugs = facetItems.map(specs => specs.split("_").join("-"))

            GetSpecToolTips(tipSlugs).then(response => {
                if( Array.isArray(response) && response.length ){
                    const obj = {};

                    response.forEach(tips => {
                        obj[tips.akeneoId] = tips.tipBody
                    })

                    setToolTips(obj)
                }
            })
        }

    }, [ facetItems[0], toolTips, facetItems ])


    return{
        toolTips
    }
    
}