
export function IconPanelReady(props){
    return(
        <svg viewBox="0 0 221 39" xmlSpace="preserve" {...props} aria-label="panel ready">
            <g id="Panel_Ready_Flag" data-name="Panel Ready Flag" transform="translate(-708 -571)">
                <rect id="Rectangle_850" data-name="Rectangle 850" width="221" height="39" rx="19.5" transform="translate(708 571)" fill="#4c7b9a"/>
                <path id="Path_1338" data-name="Path 1338" d="M2.5,0V-2.844h2.04a2.511,2.511,0,0,0,2.688-2.58A2.5,2.5,0,0,0,4.536-8H.792V0ZM4.308-4.344H2.5V-6.5H4.308a1.073,1.073,0,0,1,1.176,1.08A1.079,1.079,0,0,1,4.308-4.344ZM16.3,0,13.212-8H11.076L7.98,0H9.924l.5-1.356H13.86L14.352,0Zm-2.9-2.856H10.9L12.144-6.3ZM25.644,0V-8h-1.7v5.028L20.232-8H18.48V0h1.7V-5.22L24,0Zm8.688,0V-1.5h-3.96V-3.312h3.876v-1.5H30.372V-6.5h3.96V-8H28.668V0Zm7.86,0V-1.5H38.808V-8H37.1V0Zm13.62,0-1.8-3.048a2.3,2.3,0,0,0,1.752-2.376A2.493,2.493,0,0,0,53.076-8H49.332V0h1.7V-2.844h1.248L53.856,0ZM52.824-4.344H51.036V-6.5h1.788a1.087,1.087,0,0,1,1.2,1.08A1.087,1.087,0,0,1,52.824-4.344ZM64.152,0V-1.5h-3.96V-3.312h3.876v-1.5H60.192V-6.5h3.96V-8H58.488V0ZM74.388,0,71.3-8H69.168l-3.1,8h1.944l.5-1.356h3.432L72.444,0Zm-2.9-2.856h-2.5L70.236-6.3ZM79.728,0a3.913,3.913,0,0,0,4.248-4A3.922,3.922,0,0,0,79.728-8H76.572V0Zm0-1.5H78.276v-5h1.452A2.342,2.342,0,0,1,82.236-4,2.4,2.4,0,0,1,79.728-1.5ZM90.084,0V-3.276L93.144-8H91.2L89.232-4.788,87.24-8H85.3L88.38-3.276V0Z" transform="translate(771.208 594.004)" fill="#fff"/>
            </g>        
        </svg>

    )
}