import clsx from 'clsx';
import Style from './ReviewStars.module.scss';

function ReviewStars({ average_score, total, onlyStars = false, isLarge = false }){

    return(
        <dl className={Style.block}>
            <dt 
                className={clsx(Style.rating, isLarge && Style.large)} 
                style={{"--rating": average_score}} 
                aria-label={`Rating of ${average_score} out of 5.`} 
            />
            
            {!onlyStars ? (
                <>
                    {total ? (
                        <dd className={Style.total} aria-label="total rating">
                            ({total})
                        </dd>
                    ):null}
                </>
            ):(
                <dd></dd>  
            )}
        </dl>
    );
}


export default ReviewStars;