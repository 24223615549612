
export function IconBadgeCyberSale(props){
    return(
            <svg xmlSpace="preserve" viewBox="0 0 68 32" {...props} aria-label="cyber sale">
            <g id="Cyber_Sale_Flag" data-name="Cyber Sale Flag" clip-path="url(#clip-Cyber_Sale_Flag)">
                <rect width="68" height="32" fill="#fff"/>
                <g id="Group_1" data-name="Group 1" transform="translate(359.64 -255)">
                <path id="Path_10" data-name="Path 10" d="M0,0H50L34.36,29.392h-50Z" transform="translate(-342 258)" fill="#5ca9a4"/>
                <path id="Path_8" data-name="Path 8" d="M0,0H50L34.36,29.392h-50Z" transform="translate(-344 255)" fill="#2e303b"/>
                <path id="Path_9" data-name="Path 9" d="M-17.38-2.94c0,1.88,1.57,3.06,3.48,3.06a3.66,3.66,0,0,0,3.04-1.53l-1.58-1.02a1.653,1.653,0,0,1-1.35.77,1.42,1.42,0,0,1-1.5-1.42A1.869,1.869,0,0,1-13.46-5a1.3,1.3,0,0,1,1.35,1.04l1.94-.61a3.077,3.077,0,0,0-3.2-2.21A3.808,3.808,0,0,0-17.38-2.94ZM-6.69,0l.58-2.65,3.37-4.02H-5.11L-6.83-4.42l-.74-2.25H-9.74l1.61,4.02L-8.71,0ZM.94,0C2.7,0,3.31-1.11,3.31-1.99a1.316,1.316,0,0,0-.95-1.32,1.753,1.753,0,0,0,1.5-1.7A1.71,1.71,0,0,0,1.93-6.67H-1.78L-3.25,0Zm.77-4.67a.46.46,0,0,1-.52.48H-.31l.19-.84H1.32A.349.349,0,0,1,1.71-4.67ZM1.3-2.14a.541.541,0,0,1-.61.5H-.87l.2-.91H.89A.394.394,0,0,1,1.3-2.14ZM9.38,0l.37-1.68H6.71l.19-.85H9.87l.37-1.68H7.27l.17-.78h3.04l.37-1.68H5.79L4.32,0Zm7.47,0-.66-2.25a2.549,2.549,0,0,0,1.64-2.4,2.082,2.082,0,0,0-2.24-2.02H12.35L10.88,0H12.9l.47-2.14h.81L14.63,0ZM13.74-3.82,14-4.99h1.15a.529.529,0,0,1,.59.54c0,.41-.33.63-.82.63ZM-13.355,7.82a4.391,4.391,0,0,0,3.34,1.3c1.72,0,2.79-1.03,2.79-2.27,0-1.27-1.37-1.78-2.32-2.08-.5-.16-.85-.31-.85-.53,0-.18.15-.34.52-.34a2.9,2.9,0,0,1,1.95.81l1.18-1.37a4.141,4.141,0,0,0-2.91-1.11c-1.9,0-2.81,1.23-2.81,2.12,0,1.4,1.36,1.88,2.3,2.18.52.17.84.32.84.56,0,.17-.15.33-.56.33a3.116,3.116,0,0,1-2.3-1ZM.3,9l-.99-6.67h-2.54L-7.165,9h2.29l.45-.86h2.55l.08.86Zm-2.31-2.54h-1.52l1.29-2.45ZM5.805,9,6.2,7.24H3.665l1.08-4.91H2.725L1.255,9Zm6.47,0,.37-1.68H9.605l.19-.85h2.97l.37-1.68h-2.97l.17-.78h3.04l.37-1.68H8.685L7.215,9Z" transform="translate(-327 269)" fill="#dfe9ef"/>
                </g>
            </g>
            </svg>
    )
}