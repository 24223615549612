
export function IconBadgeNewColor(props){
    return(
        <svg xmlSpace="preserve" viewBox="0 0 50 50" {...props} aria-label="new color">
            <g id="New_Color_Badge" data-name="New Color Badge" transform="translate(-71 -70)">
                <g id="Sale_Badge" data-name="Sale Badge" transform="translate(-61 63)">
                <g id="Group_2" data-name="Group 2">
                    <path id="Path_5" data-name="Path 5" d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z" transform="translate(132 7)" fill="#5ca9a4"/>
                </g>
                </g>
                <path id="Path_7" data-name="Path 7" d="M-5.72,0V-6.67H-7.14v4.19l-3.09-4.19h-1.46V0h1.42V-4.35L-7.09,0ZM1.52,0V-1.25h-3.3V-2.76H1.45V-4.01H-1.78V-5.42h3.3V-6.67H-3.2V0Zm8.92,0,1.9-6.67h-1.6L9.58-1.85,8.32-6.67H7.21L5.94-1.85,4.77-6.67H3.18L5.09,0H6.6L7.76-4.58,8.93,0ZM-15.29,10.12A3.072,3.072,0,0,0-12.5,8.48l-1.22-.59a1.789,1.789,0,0,1-1.57.97,2.077,2.077,0,0,1-2.09-2.19,2.077,2.077,0,0,1,2.09-2.19,1.808,1.808,0,0,1,1.57.97l1.22-.6a3.053,3.053,0,0,0-2.79-1.63,3.389,3.389,0,0,0-3.55,3.45A3.389,3.389,0,0,0-15.29,10.12Zm8,0A3.351,3.351,0,0,0-3.8,6.67,3.351,3.351,0,0,0-7.29,3.22a3.349,3.349,0,0,0-3.48,3.45A3.349,3.349,0,0,0-7.29,10.12Zm0-1.26A2.022,2.022,0,0,1-9.31,6.67,2.017,2.017,0,0,1-7.29,4.48,2.025,2.025,0,0,1-5.26,6.67,2.03,2.03,0,0,1-7.29,8.86ZM2.64,10V8.75H-.18V3.33H-1.6V10Zm5.08.12a3.351,3.351,0,0,0,3.49-3.45A3.351,3.351,0,0,0,7.72,3.22,3.349,3.349,0,0,0,4.24,6.67,3.349,3.349,0,0,0,7.72,10.12Zm0-1.26A2.022,2.022,0,0,1,5.7,6.67a2.031,2.031,0,1,1,4.05,0A2.03,2.03,0,0,1,7.72,8.86ZM18.81,10l-1.5-2.54a1.914,1.914,0,0,0,1.46-1.98,2.077,2.077,0,0,0-2.24-2.15H13.41V10h1.42V7.63h1.04L17.18,10ZM16.32,6.38H14.83V4.58h1.49a.905.905,0,1,1,0,1.8Z" transform="translate(96 93)" fill="#fff"/>
            </g>
        </svg>
    )
}