
/**
 * Get tool tips from CMS
 * @param {array} slugs 
 * @returns 
 */

export default function GetToolTips(slugs = []){
    return`
        query getToolTips {
            entries(section: "toolTips", slug: ${JSON.stringify(slugs)}) {
                ... on toolTips_default_Entry {
                    tipBody
                    akeneoId
                }
            }
        }
    `
}

