import { useEffect, useState } from "react"

export default function useButtonFeedback(){
    const [ loadingState, setLoadingState ] = useState({
        loading: false,
        success: false,
        err: false
    })


    useEffect(() => {
        // if error, reset the error state after 3 seconds
        if( loadingState.err ){
            setTimeout(() => {
                setLoadingState({
                    ...loadingState,
                    err: false
                })

            }, 3000)
        }

        // if success, reset the success state after 3 seconds
        if( loadingState.success ){
            setTimeout(() => {
                setLoadingState({
                    ...loadingState,
                    success: false
                })

            }, 3000)
        }

    }, [ 
        loadingState.err, 
        loadingState.success 
    ])


    function setLoading(state){
        setLoadingState({
            ...loadingState,
            loading: state
        })
    }

    function setSuccess(state){
        setLoadingState({
            ...loadingState,
            success: state
        })
    }

    function setError(state){
        setLoadingState({
            ...loadingState,
            err: state
        })
    }


    return{
        loading: loadingState.loading,
        success: loadingState.success,
        err: loadingState.err,
        setLoading,
        setSuccess,
        setError,
    }
}