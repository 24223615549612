



export const SearchApiConstants = {
    END_POINTS: {
        SEARCH: '/products',
        CONTENT_FEED: '/content-feed',
        AUTO: '/autocomplete',
        PRODUCTS_VIEWED: '/track/products-viewed',
        PRODUCTS_CLICKED: '/track/products-clicked',
        PRODUCTS_CONVERTED: '/track/products-converted',
    },
    SEARCH_DEFAULT_PARAMS: {
        withFacets: 'true',
        withSortingOptions: 'true',
        resultsPerPage: 48
    },
    AUTO_DEFAULT_PARAMS: {
        resultsPerPage: 4,
        querySuggestions: 'true',
        highlightResults: 'content_feed',
    }
}
