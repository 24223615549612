export default class ProductApi{
    // constructor(){
    //     this.watch()
    // }


/*
    watch(){
        axios.interceptors.request.use( x => {
            // to avoid overwriting if another interceptor
            // already defined the same object (meta)
            x.meta = x.meta || {}
            x.meta.requestStartedAt = new Date().getTime();
            return x;
        })
        
        axios.interceptors.response.use( x => {
                console.log(`Execution time for: ${x.config.url} - ${ new Date().getTime() - x.config.meta.requestStartedAt} ms`)
                return x;
            },
            // Handle 4xx & 5xx responses
            x => {
                console.error(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
                throw x;
            }
        )
    }
*/


    getToken(){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("email", "admin@authenteak.com");
        urlencoded.append("password", "$*wD689ww(mp*2c");

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
        };

        return fetch(`${process.env.NEXT_PUBLIC_api_endpoint}/api/oauth/token/generate`, requestOptions)
            .then(response => response.text())
            .then(result => JSON.parse(result))
            .catch(error => console.log('error', error));
    }




    async get(apiMethod){
        return await fetch(process.env.NEXT_PUBLIC_api_endpoint + apiMethod, {
                method: "GET",
                credentials: 'include',
                cache: 'force-cache',
                revalidate: 3600,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.NEXT_PUBLIC_product_api_token}`
                }
            })
            .then(response => response.text())
            .then(result => {
                try{
                    return JSON.parse(result)

                }catch(e){
                    return {}
                }
                
            })
            .catch(error => {
                console.log('error', error)
                return error
            });
    }




}